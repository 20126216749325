import * as React from "react";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Slide } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

interface snackbarProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function NewOrderSnackbar({
  open,
  setOpen,
}: snackbarProps) {
  function playNotificationSound() {
    const audio = new Audio("/assets/notification_sound.mp3");
    audio.play();
  }

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    if (open) {
      playNotificationSound();
    }
  }, [open]);
  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={5000}
        open={open}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
      >
        <Alert
          onClose={handleClose}
          severity="info"
          icon={<NotificationsActiveIcon />}
          sx={{
            width: "100%",
            maxWidth: "100%",
            height: "auto",
          }}
        >
          New Order Received
        </Alert>
      </Snackbar>
    </Box>
  );
}
