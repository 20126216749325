import gql from "graphql-tag";

const query = gql`
  query Me($domain: String!) {
    getPropertyByDomain(domain: $domain) {
      id
      img
      name
      primary_color
      primary_light
      primary_text_color
      secondary_text_color
    }
  }
`;

export default query;
