import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { DineInItem, Selection } from "../../generated/graphql";
import useDashboard from "../../pages/Dashboard/dashboard-hook";
import { useForm } from "react-hook-form";
import { cloneDeep } from "lodash";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));
const AddItem = ({ newOrder, setNewOrder }: any) => {
  const { dineInList } = useDashboard();

  const [filterdata, setFilterData] = useState<any>("");
  const [searchText, setSearchText] = useState<string>("");

  // <---------srarch the dine-in item---------->
  const handleFilter = (event: any) => {
    const search = event.target.value;
    const newFilter: any = dineInList?.getEdibles?.filter((val) => {
      return val?.name.toLowerCase().includes(search.toLowerCase());
    });
    if (search === "") {
      setFilterData([""]);
    } else setFilterData(newFilter);
    setSearchText(search);
  };

  // <----set the filter data in search input----------->
  const onSearch = (serchterm: any) => {
    setFilterData(serchterm);
    setSearchText(serchterm.name);
  };

  // <-----add the filter data on the modify order--------->

  // TODO: Please check the logic here, how come setChecked was not used
  const [checked] = React.useState<any>(false);
  const [select, setSelect] = React.useState<any>([]);
  const { register } = useForm();
  const [cartSelections, setCartSelections] = useState<Selection[]>();
  const [checkedObj, setCheckedObj] = useState<{ [key: string]: boolean }>({});
  // const [snackbar, setSnackBar] = useState(false);
  // const [msg, setMsg] = useState("");

  useEffect(() => {
    if (!cartSelections) return;
    let itemIdObj: { [key: string]: boolean } = {};
    cartSelections.forEach((selection) =>
      selection.selection_items.forEach(
        (selectionItem) => (itemIdObj[selectionItem!.id] = true)
      )
    );
    setCheckedObj(itemIdObj);
  }, [cartSelections]);

  function handleSelctionChange(
    selectionIndex: number,
    selectionItemIndex: number,
    checked: boolean,
    currentItem: DineInItem
  ): void {
    if (!currentItem.selections) {
      return;
    }
    if (checked) {
      if (cartSelections) {
        const copy = cloneDeep(cartSelections);
        const index = (copy || []).findIndex(
          (item) =>
            item.id === (currentItem?.selections || [])[selectionIndex]?.id
        );
        if (index !== -1) {
          const limit = copy[index].limit || Infinity;
          const lengthToBe = copy[index].selection_items.length + 1;
          if (lengthToBe > limit) {
            // setMsg(`You can add ${limit} choices max`);
            // setSnackBar(true);
            return;
          }
          copy[index].selection_items.push(
            currentItem?.selections[selectionIndex]!.selection_items[
              selectionItemIndex
            ]
          );
          setCartSelections(copy);
        } else {
          const selection = cloneDeep(currentItem.selections[selectionIndex]);
          const selectionItem =
            currentItem.selections[selectionIndex]?.selection_items[
              selectionItemIndex
            ];
          selection!.selection_items = [selectionItem!];
          setCartSelections([...cartSelections, selection!]);
        }
      } else {
        const selection = cloneDeep(currentItem.selections[selectionIndex]);
        const selectionItem =
          currentItem.selections[selectionIndex]?.selection_items[
            selectionItemIndex
          ];
        selection!.selection_items = [selectionItem!];
        setCartSelections([selection!]);
      }
    } else {
      if (!cartSelections) {
        return;
      }
      const copy = cloneDeep(cartSelections);
      const index = (copy || []).findIndex(
        (item) =>
          item.id === (currentItem?.selections || [])[selectionIndex]?.id
      );
      if (copy[index].selection_items.length <= 1) {
        copy.splice(index, 1);
      } else {
        const siIndex = copy[index].selection_items.findIndex(
          (item) =>
            item?.id ===
            (currentItem?.selections || [])[selectionIndex]?.selection_items[
              selectionIndex
            ]
        );
        copy[index].selection_items.splice(siIndex, 1);
      }
      setCartSelections(copy);
    }
  }

  const handleChange = (event: any) => {
    const tuple = event.target.value.split(",");
    let updatedList = [...select];
    if (event.target.checked) {
      updatedList = [
        ...select,
        { name: tuple[0], price: parseFloat(tuple[1]) },
      ];
    } else {
      updatedList.splice(
        select.findIndex((i: any) => i.name === tuple[0]),
        1
      );
    }

    setSelect(updatedList);
  };

  const addItem = () => {
    if (!filterdata) {
      return;
    } else {
      let updatedOrder = { ...newOrder };

      const index = updatedOrder?.cart_items.findIndex(
        (p: any) => p.dineInItem_id === filterdata?.id
      );
      if (index >= 0) {
        const item = updatedOrder?.cart_items[index];
        updatedOrder.cart_items.splice(index, 1, {
          ...item,
          quantity: item?.quantity + 1,
          extras: select,
          selections: cartSelections,
        });
      } else {
        updatedOrder.cart_items.push({
          dineInItem_name: filterdata.name,
          dineInItem_id: filterdata.id,
          dineInItem_price: filterdata.price,
          quantity: 1,
          extras: select,
          selections: cartSelections,
        });
      }
      setNewOrder(updatedOrder);
      setCartSelections(undefined);
      setCheckedObj({});
      setSearchText("");
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mt={1} mb={1}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search item…"
            value={searchText}
            onChange={handleFilter}
          />
        </Search>

        <Button
          onClick={addItem}
          size="small"
          color="success"
          variant="contained"
        >
          Add item
        </Button>
      </Stack>

      {filterdata.length !== 0 && (
        <Box sx={{height:200,overflow:"auto"}}>
          {Array.isArray(filterdata) &&
            filterdata?.map((v: DineInItem, key: number) => {
              return (
                <>
                  <div
                    key={key}
                    style={{ cursor: "pointer" }}
                    onClick={() => onSearch(v)}
                  >
                    {v?.name}
                  </div>
                  {v?.extraOptions?.length !== 0 && (
                    <Typography
                      variant="body1"
                      fontStyle="italic"
                      fontWeight={600}
                    >
                      Extras:-
                    </Typography>
                  )}
                  {v?.extraOptions?.map((ex: any, i: number) => {
                    return (
                      <Box style={{ marginTop: "-14px" }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked[i]}
                                value={[ex.name, ex.price]}
                                onChange={handleChange}
                              />
                            }
                            label={ex.name}
                          />
                        </FormGroup>
                      </Box>
                    );
                  })}
                  {v?.selections && (
                    <Typography
                      variant="body1"
                      fontStyle="italic"
                      fontWeight={600}
                    >
                      Selections:-
                    </Typography>
                  )}
                  {v?.selections?.map((select: any, index: number) => {
                    return (
                      <>
                        <Typography>{select.name}</Typography>
                        {select?.selection_items?.map(
                          (sel: any, nestedIndex: number) => (
                            <Box
                              key={nestedIndex}
                              sx={{
                                mb: 1,
                                display: "flex",
                                alignItems: "center",
                                textTransform: "capitalize",
                                justifyContent: "space-between",
                              }}
                            >
                              <input
                                type="hidden"
                                value={sel?.name}
                                {...register(`selection_items
                              .${nestedIndex}.name`)}
                              />
                              <input
                                type="number"
                                value={sel?.price}
                                style={{ display: "none" }}
                                {...register(`selection_items
                              .${nestedIndex}.price`)}
                              />
                              <Typography variant="body2" sx={{ flex: 3 }}>
                                {sel?.name}
                              </Typography>
                              {/* <Typography variant="body2" sx={{ flex: 1, textAlign: 'center' }}>
                               {sel.price}
                            </Typography> */}
                              <Box sx={{ flex: 1, textAlign: "center" }}>
                                {/* <Checkbox {...register(`extras.${index}.checked`)} /> */}

                                <Checkbox
                                  checked={checkedObj[sel?.id || ""] || false}
                                  onChange={(e) =>
                                    handleSelctionChange(
                                      index,
                                      nestedIndex,
                                      e.target.checked,
                                      v
                                    )
                                  }
                                />
                              </Box>
                            </Box>
                          )
                        )}
                      </>
                    );
                  })}

                  <Divider />
                </>
              );
            })}
        </Box>
      )}
    </>
  );
};

export default AddItem;
