import Typography from "@mui/material/Typography";
import {useState,useEffect} from 'react'

interface Props {
    created_at: string;
};

const OrderTimer = ({created_at}: Props) => {

    const getTimeDiff = (created_at:string, now: number) => {
        const diff = now - new Date(created_at).valueOf();
        const diffSeconds = diff/1000;
        let hours = Math.floor(diffSeconds / 3600)
        const hrString = hours.toString().padStart(2, '0');
        let minutes = Math.floor((diffSeconds - (hours * 3600)) / 60)
        const minString = minutes.toString().padStart(2, '0');
        let seconds = Math.floor(diffSeconds - (hours * 3600) - (minutes * 60))
        const secString = seconds.toString().padStart(2, '0');
        return `${hrString}:${minString}:${secString}`;
      }    
    const [time, setTime] = useState(getTimeDiff(created_at,Date.now()))

    useEffect(() => {
        const interval = setInterval(() => setTime(getTimeDiff(created_at,Date.now())), 1000);
        return () => {
            clearInterval(interval);
        };
    });
    return (
        <Typography color="#fff" style={{ fontWeight: 600 }}>
            {time}
        </Typography>
    )
}

export default OrderTimer;