import { createContext, useContext } from "react"
export type SearchContent = {
  searchItem: string
  setSearchItem:(c: string) => void
}
export const SearchContext = createContext<SearchContent>({
searchItem: "", // set a default value
setSearchItem: () => {},
})
export const useSearchContext = () => useContext(SearchContext)
