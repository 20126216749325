import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Link, useNavigate } from "react-router-dom";
import {Stack} from "@mui/material";
import { Auth, Cache } from "aws-amplify";
import { SearchContext} from "../context/context";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IHeaderProps {
  children?: React.ReactNode;
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

export default function PrimarySearchAppBar({ children }: IHeaderProps) {
  const [searchItem, setSearchItem] = React.useState<string>("");
  const navigate = useNavigate();

  const Logouts = () => {
    Cache.removeItem("session");
    Auth.signOut();
    navigate("/login", { replace: true });
    window.location.reload();
  };

  return (
    <SearchContext.Provider value={{ searchItem, setSearchItem }}>
      <Box sx={{ flexGrow: 1, overflowX: "hidden", position: "sticky" }}>
        <AppBar sx={{ bgcolor: "#e4e8ed", color: "#2a2828" }}>
          <Toolbar>
            <Link to="/" style={{ textDecoration: "none", color: "#1e1d1d" }}>
                <Box
                width="180px"
              component="img"
              src="https://ennovatech.com/assets/images/company-logo/hotlync.svg"
              alt="logo"
            />
            </Link>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </Search>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Link
                to="/orderHistory"
                style={{ textDecoration: "none", color: "#1e1d1d" }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  mt={1}
                  mr={4}
                >
                  <Typography color="#1e1d1d" style={{ fontWeight: 600 }}>
                    {" "}
                    Order History
                  </Typography>
                  <FileCopyIcon style={{ color: "#1e1d1d" }} />
                </Stack>
              </Link>
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "#1e1d1d" }}
                onClick={Logouts}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  mt={1}
                  mr={4}
                >
                  <Typography color="#1e1d1d" style={{ fontWeight: 600 }}>
                    {" "}
                    Logout
                  </Typography>
                  <LogoutIcon style={{ color: "#1e1d1d" }} />
                </Stack>
              </Link>
              <Link
                to="/"
                style={{ textDecoration: "none", color: "#1e1d1d" }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  mt={1}
                  mr={4}
                >
                  <Typography color="#1e1d1d" style={{ fontWeight: 600 }}>
                    {" "}
                    Go Back
                  </Typography>
                  <ArrowBackIcon style={{ color: "#1e1d1d" }} />
                </Stack>
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
        {children}
      </Box>
    </SearchContext.Provider>
  );
}
