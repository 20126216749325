import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import OrderCard from "../../components/OrderCard";
import { useSearchContext } from "../../context/context";

import useDashboard from "../Dashboard/dashboard-hook";
import NewOrderSnackbar from "../../components/Snackbar";

const Dashboard = () => {
  const {
    Neworder,
    OrderInprogress,
    handleClickAcceptOrder,
    handleClickCompleteOrder,
    loading,
    dineInItemsById,
    handleCancelOrder,
  } = useDashboard();
  
  const [open,setOpen]=useState<boolean>(false);
  const [oldCount,setOldCount]=useState<number>(0)

  useEffect(()=>{
    if(Neworder?.length > oldCount){
      setOldCount(Neworder?.length);
      setOpen(true);
    }
  },[Neworder,oldCount])

  const { searchItem } = useSearchContext();

  if (loading) {
    return <Loader />;
  }
  return (
    <Box sx={{ flexGrow: 1, ml: 3, mr: 2, mt: 10 }}>
      <NewOrderSnackbar open={open} setOpen={setOpen}/>
      <Grid container spacing={1}>
          <Grid item xs={6}>
            <h4 style={{ textAlign: "center" }}>
              New Orders ({Neworder?.length>0?Neworder?.length:0})
            </h4>
            <Grid container spacing={1} style={{ margin: "0px" }}>
              {Neworder?.filter((val: any) => {
                return (
                  val.guest_name
                    ?.toLowerCase()
                    .includes(searchItem?.toLowerCase()) ||
                  val.order_id?.toLowerCase().includes(searchItem?.toLowerCase()) ||
                  val.room_id?.toString().includes(searchItem?.toLowerCase())
                );
              }).map((ord: any) => {
                return (
                  <React.Fragment key={ord.id}>
                    <Grid item xs={12} lg={4} md={6}>
                      <OrderCard
                        key={ord.id}
                        orderRow={ord}
                        id={ord.id}
                        orderColor="#21BFAE"
                        changeOrderStatus={handleClickAcceptOrder}
                        CanselOrder={handleCancelOrder}
                        dineInItemsById={dineInItemsById}
                      />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <h4 style={{ textAlign: "center" }}>
              Orders in progress ({OrderInprogress?.length>0?OrderInprogress.length:0})
            </h4>
            <Grid container spacing={1} style={{ margin: "0px" }}>
              {OrderInprogress?.filter((val: any) => {
                return (
                  val.guest_name
                    ?.toLowerCase()
                    .includes(searchItem?.toLowerCase()) ||
                  val.order_id?.toLowerCase().includes(searchItem?.toLowerCase()) ||
                  val.room_id?.toString().includes(searchItem?.toLowerCase())
                );
              }).map((ord: any) => {
                return (
                  <React.Fragment key={ord.id}>
                    <Grid item xs={12} lg={4} md={6}>
                      <OrderCard
                        key={ord.id}
                        orderRow={ord}
                        id={ord.id}
                        orderColor="#32B469"
                        changeOrderStatus={handleClickCompleteOrder}
                        CanselOrder={handleCancelOrder}
                        dineInItemsById={dineInItemsById}
                      />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
