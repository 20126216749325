import { Routes, Route } from "react-router-dom";
import { memo } from "react";
import Layout from "../layout";
import Orders from "./Orders";
import Dashboard from "./Dashboard";
import Settings from "./Settings";

const Routing = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/orderHistory" element={<Orders />} />
        <Route path="/setting" element={<Settings />} />
      </Routes>
    </Layout>
  );
};

export default memo(Routing);
