// import PrivateRoute from '../core/privateRoute';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Routing from "./Routing";
import PrivateRoute from "../core/privateRoute";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
      <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Route path="/*" element={<Routing />} />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
