import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  Status,
  useGetPropertyOrdersQuery,
  useDineInListQuery,
  useChangeOrderStatusMutation,
  useModifyOrderMutation,
  useCancelOrderMutation,
  useGetPropertyDetailsQuery,
} from "../../generated/graphql";

import { useAuthProvider } from "../../core/authContext";
import _ from "lodash";
import Swal from "sweetalert2";

const useDashboard = () => {
  const [changeOrderStatus] = useChangeOrderStatusMutation();

  const [modifyOrder] = useModifyOrderMutation();

  const [canselOrder] = useCancelOrderMutation();

  const [Neworder, setNewOrder] = useState<any>();
  const [OrderInprogress, setOrderInProgress] = useState<any>();
  
  const [open, setOpen] = React.useState(false);
  const { getUser } = useAuthProvider();
  const user = getUser();

  const { data: propertyDetails } = useGetPropertyDetailsQuery({
    fetchPolicy: "network-only",
    variables: {
      propertyID: user?.id,
    },
  });

  //Fetch Menu Items
  const { data: dineInList, loading: loadingDinInList } = useDineInListQuery({
    variables: {
      propertyID: user?.id,
    },
  });

  //fetch Pending Orders
  const {
    data: newOrders,
    loading: loadingNewOrders,
    refetch: reFetchNewOrders,
  } = useGetPropertyOrdersQuery({
    fetchPolicy: "no-cache",
    variables: {
      orderStatus: Status.Pending,
      propertyID: user?.id,
    },
  });
  //Fetch InProgress orders
  const {
    data: inProgressOrders,
    loading: loadingInProgressOrders,
    refetch,
  } = useGetPropertyOrdersQuery({
    fetchPolicy: "no-cache",
    variables: {
      orderStatus: Status.InProgress,
      propertyID: user?.id,
    },
  });

  useEffect(() => {
    const intervalCall = setInterval(() => {
      reFetchNewOrders();
    }, 5000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [reFetchNewOrders]);

  // fetch cartitem in neworders
  useEffect(() => {
    if (newOrders?.getOrdersByStatus) {
      const sortNewOrder = newOrders?.getOrdersByStatus?.sort(
        (a: any, b: any) => {
          return (
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
          );
        }
      );
      setNewOrder(sortNewOrder);
    } else {
      setNewOrder([]);
    }
  }, [newOrders]);

  useEffect(() => {
    if (inProgressOrders?.getOrdersByStatus) {
      const sortProgressOrder = inProgressOrders?.getOrdersByStatus?.sort((a: any, b: any) => {
        return (
          new Date(b.updated_at).valueOf() - new Date(a.updated_at).valueOf()
        );
      });
      setOrderInProgress(sortProgressOrder);
    } else {
      setOrderInProgress([]);
    }
  }, [inProgressOrders]);

  //Accept Order
  const handleClickAcceptOrder = async (
    id: string,
    order_id: string,
    room_no: number
  ) => {
    Swal.fire({
      text: `Are you sure you want to Accept Order ${order_id} for Room ${room_no}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Accept!",
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          try {
            const res = await changeOrderStatus({
              variables: {
                id: id,
                propertyID: user?.id,
                orderStatus: Status.InProgress,
              },
            });

            if (res.data?.changeOrderStatus) {
              swal({
                text: "OrderInProgress Successfully",
                icon: "success",
              });
            } else {
              swal({
                text: "Order Are Not InProgress",
                icon: "error",
              });
            }
            reFetchNewOrders();
            refetch();
          } catch (err) {
            swal({
              text: `Error -${err}`,
              icon: "error",
            });
          }
        })();
      }
    });
  };

  //Delivered Order
  const handleClickCompleteOrder = async (
    id: string,
    order_id: string,
    room_no: number
  ) => {
    Swal.fire({
      text: `Are you sure you want to complete Order ${order_id} for Room ${room_no}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Complete!",
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          try {
            const res = await changeOrderStatus({
              variables: {
                id: id,
                propertyID: user?.id,
                orderStatus: Status.Delivered,
              },
            });

            if (res.data?.changeOrderStatus) {
              swal({
                text: "Order Delivered Successfully",
                icon: "success",
              });
            } else {
              swal({
                text: "Order Not Delivered",
                icon: "error",
              });
            }
            reFetchNewOrders();
            refetch();
          } catch (err) {
            swal({
              text: `Error -${err}`,
              icon: "error",
            });
          }
        })();
      }
    });
  };

  const handleEditItems = async (items: any) => {
    const orderData = items?.cart_items?.map((cartData: any) => {
      let extras = cartData?.extras?.map((extra: any) => ({
        name: extra.name,

        price: extra.price,
      }));
      let cleanedSelections;
      if (cartData.selections) {
        cleanedSelections = JSON.parse(
          JSON.stringify(cartData.selections, (name, val) => {
            if (name === "__typename") {
              delete val[name];
            } else {
              return val;
            }
          })
        );
      }
      return {
        dineInItem_id: cartData.dineInItem_id,
        quantity: cartData.quantity,
        extras: extras,
        dineInItem_name: cartData.dineInItem_name,
        dineInItem_price: cartData.dineInItem_price,
        selections: cleanedSelections,
      };
    });

    try {
      const res = await modifyOrder({
        variables: {
          orderInput: {
            id: items?.id,
            order_id: items?.order_id,
            cart_items: orderData,
            property_id: items?.property_id,
            guest_id: items?.guest_id,
            guest_name: items?.guest_name,
            room_id: items?.room_id,
            total: items.total,
            status: Status.Pending,
            created_at: items?.created_at,
          },
        },
      });
      setOpen(false);

      if (res.data) {
        swal({
          text: "Order Modified Successfully",
          icon: "success",
        });
      } else {
        swal({
          text: "Order Not Modified",
          icon: "error",
        });
      }
      reFetchNewOrders();
      refetch();
    } catch (err) {
      swal({
        text: `Error -${err}`,
        icon: "error",
      });
    }
  };

  const handleCancelOrder = async (
    id: string,
    order_id: string,
    room_no: number
  ) => {
    Swal.fire({
      text: `Are you sure you want to Cancel Order ${order_id} for Room ${room_no}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          try {
              await canselOrder({
              variables: {
                id: id,
                propertyID: user?.id,
              },
            });
            swal({
              text: "Order cancelled Successfully",
              icon: "success",
            });
            reFetchNewOrders();
            refetch();
          } catch (err) {
            swal({
              text: `Error -${err}`,
              icon: "error",
            });
          }
        })();
      }
    });
  };
  const [dineInItemsById, setDineInItemsById] = useState<any>();
  useEffect(() => {
    if (dineInList?.getEdibles?.length) {
      const byKey = _.keyBy(dineInList?.getEdibles, function (o) {
        return o?.id;
      });
      setDineInItemsById(byKey);
    }
  }, [dineInList]);

  return {
    details: propertyDetails?.getProperty,
    Neworder: Neworder,
    dineInList,
    dineInItemsById,
    OrderInprogress: OrderInprogress,
    inProgressOrders,
    loading: loadingNewOrders || loadingInProgressOrders || loadingDinInList,
    handleClickAcceptOrder,
    handleClickCompleteOrder,
    handleEditItems,
    handleCancelOrder,
    open,
    setOpen,
  };
};

export default useDashboard;
