import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSJSON: any;
};

export type About = {
  __typename?: "About";
  desc: Scalars["String"];
  img: Scalars["String"];
};

export type AboutInput = {
  desc: Scalars["String"];
  img: Scalars["String"];
};

export type Allergens = {
  __typename?: "Allergens";
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type AllergensInput = {
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type Booking = {
  __typename?: "Booking";
  amount: Scalars["Int"];
  aptmt_date_time: Scalars["String"];
  assigned_to?: Maybe<Array<Maybe<Scalars["String"]>>>;
  guest_email?: Maybe<Scalars["String"]>;
  guest_name: Scalars["String"];
  guest_phone?: Maybe<Scalars["String"]>;
  guest_room_number?: Maybe<Scalars["String"]>;
  guest_type?: Maybe<Scalars["String"]>;
  hours: Scalars["String"];
  id: Scalars["ID"];
  property_id: Scalars["String"];
  status: Scalars["String"];
  treatments?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type BookingInput = {
  amount: Scalars["Int"];
  aptmt_date_time: Scalars["String"];
  assigned_to?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  guest_email?: InputMaybe<Scalars["String"]>;
  guest_name: Scalars["String"];
  guest_phone?: InputMaybe<Scalars["String"]>;
  guest_room_number?: InputMaybe<Scalars["String"]>;
  guest_type?: InputMaybe<Scalars["String"]>;
  hours: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  property_id: Scalars["String"];
  status: Scalars["String"];
  treatments?: InputMaybe<Array<InputMaybe<BookingTreatmentInput>>>;
};

export type BookingTreatmentInput = {
  assigned_to?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  duration: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
};

export type Cart = {
  __typename?: "Cart";
  dineInItem_id: Scalars["String"];
  dineInItem_name: Scalars["String"];
  dineInItem_price: Scalars["Float"];
  extras?: Maybe<Array<Maybe<ExtraOptions>>>;
  quantity: Scalars["Int"];
  selections?: Maybe<Array<Maybe<Selection>>>;
};

export type CartInput = {
  dineInItem_id: Scalars["String"];
  dineInItem_name: Scalars["String"];
  dineInItem_price: Scalars["Float"];
  extras?: InputMaybe<Array<InputMaybe<ExtraOptionsInput>>>;
  quantity: Scalars["Int"];
  selections?: InputMaybe<Array<InputMaybe<SelectionInput>>>;
};

export type Condiments = {
  condimentId?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
};

export enum Days {
  Friday = "FRIDAY",
  Monday = "MONDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
  Thursday = "THURSDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY",
}

export enum Diet_Type {
  NonVegan = "NonVegan",
  None = "None",
  Vegan = "Vegan",
}

export type DineInCategories = {
  __typename?: "DineInCategories";
  desc?: Maybe<Scalars["String"]>;
  icon: Scalars["String"];
  id: Scalars["ID"];
  is_Activated?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type DineInCategoriesInput = {
  desc?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_Activated?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type DineInItem = {
  __typename?: "DineInItem";
  allergens?: Maybe<Array<Maybe<Scalars["String"]>>>;
  availabletime?: Maybe<Scalars["String"]>;
  desc: Scalars["String"];
  extraOptions?: Maybe<Array<Maybe<ExtraOptions>>>;
  id: Scalars["ID"];
  img: Scalars["String"];
  isVegan?: Maybe<Diet_Type>;
  is_Activated?: Maybe<Scalars["Boolean"]>;
  is_recommended?: Maybe<Scalars["Boolean"]>;
  menu_item_id?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  price: Scalars["Float"];
  property_id: Scalars["String"];
  section?: Maybe<Array<Maybe<Scalars["String"]>>>;
  selections?: Maybe<Array<Maybe<Selection>>>;
  sequence?: Maybe<Scalars["Int"]>;
  spice_level?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type DineInItemInput = {
  allergens?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  availabletime?: InputMaybe<Scalars["String"]>;
  desc: Scalars["String"];
  extraOptions?: InputMaybe<Array<InputMaybe<ExtraOptionsInput>>>;
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  isVegan?: InputMaybe<Diet_Type>;
  is_Activated?: InputMaybe<Scalars["Boolean"]>;
  is_recommended?: InputMaybe<Scalars["Boolean"]>;
  menu_item_id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  price: Scalars["Float"];
  property_id: Scalars["String"];
  section?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  selections?: InputMaybe<Array<InputMaybe<SelectionInput>>>;
  sequence?: InputMaybe<Scalars["Int"]>;
  spice_level?: InputMaybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type DineInSection = {
  __typename?: "DineInSection";
  default_category?: Maybe<Scalars["String"]>;
  desc?: Maybe<Scalars["String"]>;
  end_time?: Maybe<Scalars["String"]>;
  icon: Scalars["String"];
  id: Scalars["ID"];
  is_Activated?: Maybe<Scalars["Boolean"]>;
  message?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  start_time?: Maybe<Scalars["String"]>;
  time_based?: Maybe<Scalars["Boolean"]>;
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type DineInSectionInput = {
  default_category?: InputMaybe<Scalars["String"]>;
  desc?: InputMaybe<Scalars["String"]>;
  end_time?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_Activated?: InputMaybe<Scalars["Boolean"]>;
  message?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  start_time?: InputMaybe<Scalars["String"]>;
  time_based?: InputMaybe<Scalars["Boolean"]>;
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type Dining = {
  __typename?: "Dining";
  desc: Scalars["String"];
  id: Scalars["ID"];
  img: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  service_id?: Maybe<Scalars["Int"]>;
};

export type DiningInput = {
  desc: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  service_id?: InputMaybe<Scalars["Int"]>;
};

export type DirectionsAndContact = {
  __typename?: "DirectionsAndContact";
  address: Scalars["String"];
  email: Scalars["String"];
  img: Scalars["String"];
  phone: Scalars["String"];
};

export type DirectionsAndContactInput = {
  address: Scalars["String"];
  email: Scalars["String"];
  img: Scalars["String"];
  phone: Scalars["String"];
};

export type ExtraOptions = {
  __typename?: "ExtraOptions";
  name: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
};

export type ExtraOptionsInput = {
  name: Scalars["String"];
  price?: InputMaybe<Scalars["Float"]>;
};

export type Feedback = {
  __typename?: "Feedback";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type FeedbackInput = {
  feedback: Scalars["String"];
  feedback_type_id: Scalars["Int"];
  property_id: Scalars["String"];
  room_id: Scalars["String"];
};

export type Guest = {
  __typename?: "Guest";
  guest_id?: Maybe<Scalars["String"]>;
  guest_name?: Maybe<Scalars["String"]>;
  room?: Maybe<Scalars["String"]>;
};

export type GuestInput = {
  guest_id?: InputMaybe<Scalars["String"]>;
  room?: InputMaybe<Scalars["String"]>;
};

export type HotelService = {
  __typename?: "HotelService";
  desc?: Maybe<Scalars["String"]>;
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  img?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  sub_properties?: Maybe<Array<Scalars["String"]>>;
  sub_services?: Maybe<Array<Maybe<SubService>>>;
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type HotelServiceInput = {
  desc?: InputMaybe<Scalars["String"]>;
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  img?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  sub_properties?: InputMaybe<Array<Scalars["String"]>>;
  sub_services?: InputMaybe<Array<InputMaybe<SubServiceInput>>>;
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type HotlyncServices = {
  __typename?: "HotlyncServices";
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  uploaded_icon?: Maybe<Scalars["String"]>;
};

export type HotlyncServicesInput = {
  icon?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  uploaded_icon?: InputMaybe<Scalars["String"]>;
};

export type Is_Activated = {
  __typename?: "Is_Activated";
  about?: Maybe<Scalars["Boolean"]>;
  dining?: Maybe<Scalars["Boolean"]>;
  directory?: Maybe<Scalars["Boolean"]>;
  hotel_rooms?: Maybe<Scalars["Boolean"]>;
  hotel_services?: Maybe<Scalars["Boolean"]>;
};

export type Is_ActivatedInput = {
  about?: InputMaybe<Scalars["Boolean"]>;
  dining?: InputMaybe<Scalars["Boolean"]>;
  directory?: InputMaybe<Scalars["Boolean"]>;
  hotel_rooms?: InputMaybe<Scalars["Boolean"]>;
  hotel_services?: InputMaybe<Scalars["Boolean"]>;
};

export type Itinerary = {
  __typename?: "Itinerary";
  description: Scalars["String"];
  duration: Scalars["String"];
  end_date: Scalars["String"];
  guest_list?: Maybe<Array<Maybe<Guest>>>;
  id: Scalars["ID"];
  is_for_all?: Maybe<Scalars["Boolean"]>;
  is_repeated?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  property_id: Scalars["String"];
  start_date: Scalars["String"];
  time: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
  venue?: Maybe<Scalars["String"]>;
};

export type ItineraryInput = {
  description: Scalars["String"];
  duration: Scalars["String"];
  end_date: Scalars["String"];
  guest_list?: InputMaybe<Array<InputMaybe<GuestInput>>>;
  id?: InputMaybe<Scalars["ID"]>;
  is_for_all?: InputMaybe<Scalars["Boolean"]>;
  is_repeated?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  start_date: Scalars["String"];
  time: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
  venue?: InputMaybe<Scalars["String"]>;
};

export type ItineraryTypes = {
  __typename?: "ItineraryTypes";
  id: Scalars["String"];
  name: Scalars["String"];
  property_id: Scalars["String"];
};

export type ItineraryTypesInput = {
  id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
};

export type Layout = {
  __typename?: "Layout";
  active?: Maybe<Scalars["Boolean"]>;
  cardName: Scalars["String"];
  sequence: Scalars["Int"];
};

export type LayoutInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  cardName: Scalars["String"];
  sequence: Scalars["Int"];
};

export type MenuItems = {
  condiments?: InputMaybe<Array<InputMaybe<Condiments>>>;
  menuitemid?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
};

export type MetaData = {
  __typename?: "MetaData";
  about: About;
  dining?: Maybe<Array<Maybe<Dining>>>;
  dining_image?: Maybe<Scalars["String"]>;
  dir_con: DirectionsAndContact;
  directory_description?: Maybe<Scalars["String"]>;
  feedback_desc?: Maybe<Scalars["String"]>;
  has_order_service_id?: Maybe<Scalars["Boolean"]>;
  hotel_name: Scalars["String"];
  hotel_rooms?: Maybe<Array<Room>>;
  hotel_services?: Maybe<Array<HotelService>>;
  hotel_services_image?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  is_Activated?: Maybe<Is_Activated>;
  order_service_id?: Maybe<Scalars["Int"]>;
  promotion_desc?: Maybe<Scalars["String"]>;
  property_id: Scalars["String"];
  rooms_image?: Maybe<Scalars["String"]>;
};

export type MetaDataInput = {
  about: AboutInput;
  dining_image?: InputMaybe<Scalars["String"]>;
  dir_con: DirectionsAndContactInput;
  directory_description?: InputMaybe<Scalars["String"]>;
  feedback_desc?: InputMaybe<Scalars["String"]>;
  has_order_service_id?: InputMaybe<Scalars["Boolean"]>;
  hotel_name: Scalars["String"];
  hotel_services_image?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  is_Activated?: InputMaybe<Is_ActivatedInput>;
  order_service_id?: InputMaybe<Scalars["Int"]>;
  promotion_desc?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  rooms_image?: InputMaybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  cancelOrder?: Maybe<Scalars["String"]>;
  changeOrderStatus?: Maybe<Scalars["String"]>;
  createFeedback?: Maybe<Response>;
  createSimphonyWithComboMeals?: Maybe<StatusResponse>;
  createSimphonyWithInvalidItem?: Maybe<StatusResponse>;
  createSimphonyWithInvalidProperty?: Maybe<StatusResponse>;
  deleteAllergens?: Maybe<Scalars["String"]>;
  deleteDineInCategory?: Maybe<Scalars["String"]>;
  deleteDineInSection?: Maybe<Scalars["String"]>;
  deleteDining?: Maybe<Scalars["String"]>;
  deleteEdible?: Maybe<Scalars["String"]>;
  deleteHotelService?: Maybe<Scalars["String"]>;
  deleteItinerary?: Maybe<Scalars["String"]>;
  deleteItineraryTypes?: Maybe<Scalars["String"]>;
  deleteOrder?: Maybe<Scalars["String"]>;
  deletePromotion?: Maybe<Scalars["String"]>;
  deleteProperty?: Maybe<Property>;
  deleteRole?: Maybe<Scalars["String"]>;
  deleteRoom?: Maybe<Scalars["String"]>;
  deleteSubProperty?: Maybe<Scalars["String"]>;
  deleteUser?: Maybe<Scalars["String"]>;
  requestService?: Maybe<Response>;
  requestServices?: Maybe<Response>;
  syncSimphonyPOSMenuItems?: Maybe<StatusResponse>;
  updateEdibleStatus?: Maybe<DineInItem>;
  updatePromotionStatus?: Maybe<Promotion>;
  upsertAllergens?: Maybe<Allergens>;
  upsertApiKey?: Maybe<Scalars["String"]>;
  upsertBooking?: Maybe<Booking>;
  upsertDineInCategory?: Maybe<DineInCategories>;
  upsertDineInSection?: Maybe<DineInSection>;
  upsertDining?: Maybe<Scalars["String"]>;
  upsertEdible?: Maybe<DineInItem>;
  upsertHotelService?: Maybe<Scalars["String"]>;
  upsertHotlyncServices?: Maybe<HotlyncServices>;
  upsertItinerary?: Maybe<Itinerary>;
  upsertItineraryTypes?: Maybe<ItineraryTypes>;
  upsertMetaData?: Maybe<Scalars["String"]>;
  upsertOrder?: Maybe<OrderResponse>;
  upsertPromotion?: Maybe<Promotion>;
  upsertProperty?: Maybe<Property>;
  upsertRole?: Maybe<Role>;
  upsertRoom?: Maybe<Room>;
  upsertSubProperty?: Maybe<SubProperty>;
  upsertUser?: Maybe<User>;
  verifyGuest?: Maybe<Response>;
};

export type MutationCancelOrderArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationChangeOrderStatusArgs = {
  id: Scalars["String"];
  orderStatus: Status;
  propertyID: Scalars["String"];
};

export type MutationCreateFeedbackArgs = {
  feedbackInput: FeedbackInput;
};

export type MutationCreateSimphonyWithComboMealsArgs = {
  propertyID: Scalars["String"];
};

export type MutationCreateSimphonyWithInvalidItemArgs = {
  propertyID: Scalars["String"];
};

export type MutationCreateSimphonyWithInvalidPropertyArgs = {
  propertyID: Scalars["String"];
};

export type MutationDeleteAllergensArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteDineInCategoryArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteDineInSectionArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteDiningArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteEdibleArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteHotelServiceArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteItineraryArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteItineraryTypesArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeleteOrderArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeletePromotionArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationDeletePropertyArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteRoleArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type MutationDeleteRoomArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteSubPropertyArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
};

export type MutationRequestServiceArgs = {
  serviceInput: ServiceInput;
};

export type MutationRequestServicesArgs = {
  servicesInput?: InputMaybe<Array<ServiceInput>>;
};

export type MutationSyncSimphonyPosMenuItemsArgs = {
  propertyID: Scalars["String"];
};

export type MutationUpdateEdibleStatusArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
  status: Scalars["Boolean"];
};

export type MutationUpdatePromotionStatusArgs = {
  id: Scalars["ID"];
  propertyID: Scalars["String"];
  status: Scalars["Boolean"];
};

export type MutationUpsertAllergensArgs = {
  allergensInput?: InputMaybe<AllergensInput>;
};

export type MutationUpsertApiKeyArgs = {
  apiKey: Scalars["String"];
  propertyID: Scalars["String"];
};

export type MutationUpsertBookingArgs = {
  bookingInput?: InputMaybe<BookingInput>;
};

export type MutationUpsertDineInCategoryArgs = {
  dineInCategoriesInput?: InputMaybe<DineInCategoriesInput>;
};

export type MutationUpsertDineInSectionArgs = {
  dineInSectionInput?: InputMaybe<DineInSectionInput>;
};

export type MutationUpsertDiningArgs = {
  diningInput: DiningInput;
};

export type MutationUpsertEdibleArgs = {
  dineInItemInput: DineInItemInput;
};

export type MutationUpsertHotelServiceArgs = {
  hotelServiceInput: HotelServiceInput;
};

export type MutationUpsertHotlyncServicesArgs = {
  hotlyncServicesInput?: InputMaybe<HotlyncServicesInput>;
};

export type MutationUpsertItineraryArgs = {
  itineraryInput?: InputMaybe<ItineraryInput>;
};

export type MutationUpsertItineraryTypesArgs = {
  itineraryTypesInput?: InputMaybe<ItineraryTypesInput>;
};

export type MutationUpsertMetaDataArgs = {
  metaInput: MetaDataInput;
};

export type MutationUpsertOrderArgs = {
  orderInput: OrderInput;
};

export type MutationUpsertPromotionArgs = {
  promotionInput: PromotionInput;
};

export type MutationUpsertPropertyArgs = {
  propertyInput: PropertyInput;
};

export type MutationUpsertRoleArgs = {
  role: RoleInput;
};

export type MutationUpsertRoomArgs = {
  roomInput: RoomInput;
};

export type MutationUpsertSubPropertyArgs = {
  subProperty: SubPropertyInput;
};

export type MutationUpsertUserArgs = {
  userInput: UserInput;
};

export type MutationVerifyGuestArgs = {
  guestName: Scalars["String"];
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type Order = {
  __typename?: "Order";
  cart_items?: Maybe<Array<Cart>>;
  comment?: Maybe<Scalars["String"]>;
  created_at: Scalars["String"];
  guest_id: Scalars["Int"];
  guest_name: Scalars["String"];
  id: Scalars["ID"];
  order_id: Scalars["String"];
  property_id: Scalars["String"];
  room_id: Scalars["Int"];
  status?: Maybe<Status>;
  total: Scalars["Float"];
  updated_at?: Maybe<Scalars["String"]>;
};

export type OrderInput = {
  cart_items?: InputMaybe<Array<CartInput>>;
  comment?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["String"]>;
  guest_id: Scalars["Int"];
  guest_name: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  order_id?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  room_id: Scalars["Int"];
  status?: InputMaybe<Status>;
  total: Scalars["Float"];
};

export type OrderResponse = {
  __typename?: "OrderResponse";
  code: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
  order?: Maybe<Order>;
};

export type PermissionTypes = {
  __typename?: "PermissionTypes";
  allergens?: Maybe<Array<Maybe<Permissions>>>;
  dine_in_categories?: Maybe<Array<Maybe<Permissions>>>;
  dine_in_menu?: Maybe<Array<Maybe<Permissions>>>;
  facilities?: Maybe<Array<Maybe<Permissions>>>;
  hotel_info?: Maybe<Array<Maybe<Permissions>>>;
  itinerary?: Maybe<Array<Maybe<Permissions>>>;
  promotions?: Maybe<Array<Maybe<Permissions>>>;
  reports?: Maybe<Array<Maybe<Permissions>>>;
  rooms?: Maybe<Array<Maybe<Permissions>>>;
  services?: Maybe<Array<Maybe<Permissions>>>;
  settings?: Maybe<Array<Maybe<Permissions>>>;
};

export type PermissionTypesInput = {
  allergens?: InputMaybe<Array<InputMaybe<Permissions>>>;
  dine_in_categories?: InputMaybe<Array<InputMaybe<Permissions>>>;
  dine_in_menu?: InputMaybe<Array<InputMaybe<Permissions>>>;
  facilities?: InputMaybe<Array<InputMaybe<Permissions>>>;
  hotel_info?: InputMaybe<Array<InputMaybe<Permissions>>>;
  itinerary?: InputMaybe<Array<InputMaybe<Permissions>>>;
  promotions?: InputMaybe<Array<InputMaybe<Permissions>>>;
  reports?: InputMaybe<Array<InputMaybe<Permissions>>>;
  rooms?: InputMaybe<Array<InputMaybe<Permissions>>>;
  services?: InputMaybe<Array<InputMaybe<Permissions>>>;
  settings?: InputMaybe<Array<InputMaybe<Permissions>>>;
  user_management?: InputMaybe<Array<InputMaybe<Permissions>>>;
};

export enum Permissions {
  Activate = "ACTIVATE",
  Add = "ADD",
  AppSetting = "APP_SETTING",
  Delete = "DELETE",
  Edit = "EDIT",
  HotelInfo = "HOTEL_INFO",
  PriceEdit = "PRICE_EDIT",
  Rearrange = "REARRANGE",
  Sync = "SYNC",
}

export type Promotion = {
  __typename?: "Promotion";
  banner_image: Scalars["String"];
  description: Scalars["String"];
  end_date: Scalars["String"];
  id: Scalars["ID"];
  is_Activated?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  start_date: Scalars["String"];
};

export type PromotionInput = {
  banner_image: Scalars["String"];
  description: Scalars["String"];
  end_date: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  is_Activated?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  start_date: Scalars["String"];
};

export type Property = {
  __typename?: "Property";
  background_color?: Maybe<Scalars["String"]>;
  background_image?: Maybe<Scalars["String"]>;
  button_bg?: Maybe<Scalars["String"]>;
  by_dining?: Maybe<Scalars["String"]>;
  chat_api_url: Scalars["String"];
  country?: Maybe<Scalars["String"]>;
  currency?: Maybe<Scalars["String"]>;
  direct_simphony_pos_check?: Maybe<Scalars["Boolean"]>;
  domain?: Maybe<Scalars["String"]>;
  font?: Maybe<Scalars["String"]>;
  has_simphony_pos?: Maybe<Scalars["Boolean"]>;
  hide_all_menu?: Maybe<Scalars["Boolean"]>;
  hotlync_api_url?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  img: Scalars["String"];
  layout?: Maybe<Array<Maybe<Layout>>>;
  logo_tile_bg?: Maybe<Scalars["String"]>;
  logo_tile_transperancy?: Maybe<Scalars["Float"]>;
  name: Scalars["String"];
  palm_icon?: Maybe<Scalars["Boolean"]>;
  palm_text?: Maybe<Scalars["String"]>;
  pos_loc_ref?: Maybe<Scalars["String"]>;
  pos_rvc_ref?: Maybe<Scalars["String"]>;
  primary_color: Scalars["String"];
  primary_light: Scalars["String"];
  primary_text_color: Scalars["String"];
  restaurant_email?: Maybe<Array<Maybe<Scalars["String"]>>>;
  restaurant_link?: Maybe<Scalars["String"]>;
  restaurant_phone?: Maybe<Scalars["String"]>;
  secondary_text_color: Scalars["String"];
  send_to_simphony?: Maybe<Scalars["Boolean"]>;
  sender_email?: Maybe<Scalars["String"]>;
  show_main_category?: Maybe<Scalars["Boolean"]>;
  show_talk_to_us?: Maybe<Scalars["Boolean"]>;
  simphony_pos_url?: Maybe<Scalars["String"]>;
  spa_id?: Maybe<Scalars["String"]>;
  star_rating?: Maybe<Scalars["Boolean"]>;
  tile_color?: Maybe<Scalars["String"]>;
  title_color?: Maybe<Scalars["String"]>;
  transperancy?: Maybe<Scalars["Float"]>;
  whatsapp_icon?: Maybe<Scalars["Boolean"]>;
};

export type PropertyInput = {
  background_color: Scalars["String"];
  background_image?: InputMaybe<Scalars["String"]>;
  button_bg?: InputMaybe<Scalars["String"]>;
  by_dining?: InputMaybe<Scalars["String"]>;
  chat_api_url: Scalars["String"];
  country?: InputMaybe<Scalars["String"]>;
  currency: Scalars["String"];
  direct_simphony_pos_check?: InputMaybe<Scalars["Boolean"]>;
  domain: Scalars["String"];
  font?: InputMaybe<Scalars["String"]>;
  has_simphony_pos?: InputMaybe<Scalars["Boolean"]>;
  hide_all_menu?: InputMaybe<Scalars["Boolean"]>;
  hotlync_api_url: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  layout?: InputMaybe<Array<InputMaybe<LayoutInput>>>;
  logo_tile_bg?: InputMaybe<Scalars["String"]>;
  logo_tile_transperancy?: InputMaybe<Scalars["Float"]>;
  name: Scalars["String"];
  palm_icon?: InputMaybe<Scalars["Boolean"]>;
  palm_text?: InputMaybe<Scalars["String"]>;
  pos_loc_ref?: InputMaybe<Scalars["String"]>;
  pos_rvc_ref?: InputMaybe<Scalars["String"]>;
  primary_color: Scalars["String"];
  primary_light: Scalars["String"];
  primary_text_color: Scalars["String"];
  restaurant_email?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  restaurant_phone?: InputMaybe<Scalars["String"]>;
  secondary_text_color: Scalars["String"];
  send_to_simphony?: InputMaybe<Scalars["Boolean"]>;
  sender_email?: InputMaybe<Scalars["String"]>;
  show_main_category?: InputMaybe<Scalars["Boolean"]>;
  show_talk_to_us?: InputMaybe<Scalars["Boolean"]>;
  simphony_pos_url?: InputMaybe<Scalars["String"]>;
  spa_id?: InputMaybe<Scalars["String"]>;
  star_rating?: InputMaybe<Scalars["Boolean"]>;
  tile_color?: InputMaybe<Scalars["String"]>;
  title_color?: InputMaybe<Scalars["String"]>;
  transperancy?: InputMaybe<Scalars["Float"]>;
  whatsapp_icon?: InputMaybe<Scalars["Boolean"]>;
};

export type Query = {
  __typename?: "Query";
  getActiveItineraries?: Maybe<Array<Maybe<Itinerary>>>;
  getAdminPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getAllDineInSections?: Maybe<Array<Maybe<DineInSection>>>;
  getAllergens?: Maybe<Array<Maybe<Allergens>>>;
  getClosedItineraries?: Maybe<Array<Maybe<Itinerary>>>;
  getDineInCategories?: Maybe<Array<Maybe<DineInCategories>>>;
  getDineInSections?: Maybe<Array<Maybe<DineInSection>>>;
  getEdibles?: Maybe<Array<Maybe<DineInItem>>>;
  getFeedbackTypeList?: Maybe<Array<Maybe<Feedback>>>;
  getFlexiPass?: Maybe<Scalars["String"]>;
  getGuest?: Maybe<Response>;
  getGuestDineInCategories?: Maybe<Array<Maybe<DineInCategories>>>;
  getGuestEdibles?: Maybe<Array<Maybe<DineInItem>>>;
  getGuestList?: Maybe<Array<Maybe<Guest>>>;
  getGuestOrders?: Maybe<Array<Maybe<Order>>>;
  getGuestPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getHotelService?: Maybe<HotelService>;
  getHotlyncServices?: Maybe<Array<Maybe<HotlyncServices>>>;
  getItinerary?: Maybe<Array<Maybe<Itinerary>>>;
  getItineraryTypes?: Maybe<Array<Maybe<ItineraryTypes>>>;
  getMeta?: Maybe<MetaData>;
  getOrders?: Maybe<Array<Maybe<Order>>>;
  getOrdersByStatus?: Maybe<Array<Maybe<Order>>>;
  getPromotions?: Maybe<Array<Maybe<Promotion>>>;
  getProperty?: Maybe<Property>;
  getPropertyByDomain?: Maybe<Property>;
  getPropertyRoles?: Maybe<Array<Maybe<Role>>>;
  getPropertyTreatments?: Maybe<Array<Maybe<Treatment>>>;
  getPropertyUsers?: Maybe<Array<Maybe<User>>>;
  getQuickServices?: Maybe<QuickServices>;
  getServices?: Maybe<ServiceByDeptmt>;
  getSubProperties?: Maybe<Array<Maybe<SubProperty>>>;
  getTaskHistory?: Maybe<Array<Maybe<TaskHistory>>>;
  getUploadSignedURL?: Maybe<SignedUrl>;
  getUser?: Maybe<User>;
  getUserByEmail?: Maybe<User>;
  simphonyDiscounts?: Maybe<StatusResponse>;
  simphonyServiceCharges?: Maybe<StatusResponse>;
  simphonySignIn?: Maybe<Scalars["String"]>;
};

export type QueryGetActiveItinerariesArgs = {
  endDate: Scalars["String"];
  propertyID: Scalars["String"];
  startDate: Scalars["String"];
};

export type QueryGetAdminPromotionsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetAllDineInSectionsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetAllergensArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetClosedItinerariesArgs = {
  propertyID: Scalars["String"];
  startDate: Scalars["String"];
};

export type QueryGetDineInCategoriesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetDineInSectionsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetEdiblesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetFeedbackTypeListArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetFlexiPassArgs = {
  password: Scalars["String"];
  propertyID: Scalars["String"];
  username: Scalars["String"];
};

export type QueryGetGuestArgs = {
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type QueryGetGuestDineInCategoriesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetGuestEdiblesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetGuestListArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetGuestOrdersArgs = {
  guestID: Scalars["Int"];
  propertyID: Scalars["String"];
};

export type QueryGetGuestPromotionsArgs = {
  currDate: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetHotelServiceArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetHotlyncServicesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetItineraryArgs = {
  guestID: Scalars["Int"];
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type QueryGetItineraryTypesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetMetaArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetOrdersArgs = {
  endDate?: InputMaybe<Scalars["String"]>;
  propertyID: Scalars["String"];
  startDate?: InputMaybe<Scalars["String"]>;
};

export type QueryGetOrdersByStatusArgs = {
  orderStatus: Status;
  propertyID: Scalars["String"];
};

export type QueryGetPromotionsArgs = {
  currDate: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetPropertyArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetPropertyByDomainArgs = {
  domain: Scalars["String"];
};

export type QueryGetPropertyRolesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetPropertyTreatmentsArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetPropertyUsersArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetQuickServicesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetServicesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetSubPropertiesArgs = {
  propertyID: Scalars["String"];
};

export type QueryGetTaskHistoryArgs = {
  guestID: Scalars["Int"];
  propertyID: Scalars["String"];
  roomID: Scalars["String"];
};

export type QueryGetUploadSignedUrlArgs = {
  extension: Scalars["String"];
  fileType: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetUserArgs = {
  id: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QueryGetUserByEmailArgs = {
  email: Scalars["String"];
  propertyID: Scalars["String"];
};

export type QuerySimphonyDiscountsArgs = {
  propertyID: Scalars["String"];
};

export type QuerySimphonyServiceChargesArgs = {
  propertyID: Scalars["String"];
};

export type QuerySimphonySignInArgs = {
  password: Scalars["String"];
  propertyID: Scalars["String"];
  username: Scalars["String"];
};

export type QuickService = {
  __typename?: "QuickService";
  department: Scalars["String"];
  id: Scalars["String"];
  qty_by_guest: Scalars["Int"];
  task: Scalars["String"];
};

export type QuickServices = {
  __typename?: "QuickServices";
  code: Scalars["Int"];
  quicktasklist?: Maybe<Array<Maybe<QuickService>>>;
};

export type Response = {
  __typename?: "Response";
  code: Scalars["String"];
  guest_arrival?: Maybe<Scalars["String"]>;
  guest_departure?: Maybe<Scalars["String"]>;
  guest_id?: Maybe<Scalars["Int"]>;
  guest_name?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  property_id?: Maybe<Scalars["String"]>;
  room?: Maybe<Scalars["String"]>;
};

export type Role = {
  __typename?: "Role";
  id: Scalars["ID"];
  name: Scalars["String"];
  permission?: Maybe<PermissionTypes>;
  property_id: Scalars["String"];
};

export type RoleInput = {
  id: Scalars["ID"];
  name: Scalars["String"];
  permission?: InputMaybe<PermissionTypesInput>;
  property_id: Scalars["String"];
};

export type Room = {
  __typename?: "Room";
  desc: Scalars["String"];
  id: Scalars["ID"];
  img: Scalars["String"];
  property_id: Scalars["String"];
  room_type: Scalars["String"];
  sequence?: Maybe<Scalars["Int"]>;
  size?: Maybe<Scalars["Int"]>;
};

export type RoomInput = {
  desc: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  img: Scalars["String"];
  property_id: Scalars["String"];
  room_type: Scalars["String"];
  sequence?: InputMaybe<Scalars["Int"]>;
  size?: InputMaybe<Scalars["Int"]>;
};

export type Selection = {
  __typename?: "Selection";
  id: Scalars["String"];
  limit?: Maybe<Scalars["Int"]>;
  min_limit?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  selection_items: Array<Maybe<SelectionItem>>;
};

export type SelectionInput = {
  id: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  min_limit?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  selection_items: Array<InputMaybe<SelectionItemInput>>;
};

export type SelectionItem = {
  __typename?: "SelectionItem";
  id: Scalars["String"];
  name: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
};

export type SelectionItemInput = {
  id: Scalars["String"];
  name: Scalars["String"];
  price?: InputMaybe<Scalars["Float"]>;
};

export type Service = {
  __typename?: "Service";
  id: Scalars["Int"];
  task: Scalars["String"];
};

export type ServiceByDeptmt = {
  __typename?: "ServiceByDeptmt";
  code: Scalars["Int"];
  tasklist?: Maybe<Scalars["AWSJSON"]>;
};

export type ServiceInput = {
  menu_items?: InputMaybe<Array<InputMaybe<MenuItems>>>;
  notes?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  quantity: Scalars["Int"];
  room_id: Scalars["String"];
  schedule_task?: InputMaybe<Scalars["String"]>;
  task_id: Scalars["Int"];
};

export type SignedUrl = {
  __typename?: "SignedURL";
  presigned_upload_url?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export enum Status {
  Cancelled = "CANCELLED",
  Delivered = "DELIVERED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
}

export type StatusResponse = {
  __typename?: "StatusResponse";
  data?: Maybe<Scalars["AWSJSON"]>;
  message?: Maybe<Scalars["String"]>;
  status: Scalars["Int"];
};

export type SubProperty = {
  __typename?: "SubProperty";
  id: Scalars["ID"];
  img?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sub_services?: Maybe<Array<Maybe<SubService>>>;
};

export type SubPropertyInput = {
  id?: InputMaybe<Scalars["ID"]>;
  img?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  property_id: Scalars["String"];
  sub_services?: InputMaybe<Array<InputMaybe<SubServiceInput>>>;
};

export type SubService = {
  __typename?: "SubService";
  booking_form?: Maybe<Scalars["Boolean"]>;
  booking_url?: Maybe<Scalars["String"]>;
  desc?: Maybe<Scalars["String"]>;
  img?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  service_id?: Maybe<Scalars["Int"]>;
};

export type SubServiceInput = {
  booking_form?: InputMaybe<Scalars["Boolean"]>;
  booking_url?: InputMaybe<Scalars["String"]>;
  desc?: InputMaybe<Scalars["String"]>;
  img?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  service_id?: InputMaybe<Scalars["Int"]>;
};

export type TaskHistory = {
  __typename?: "TaskHistory";
  created_time?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  task_list?: Maybe<Scalars["Int"]>;
  task_name?: Maybe<Scalars["String"]>;
};

export type Treatment = {
  __typename?: "Treatment";
  category?: Maybe<TreatmentCategory>;
  default_durations?: Maybe<Array<Maybe<Scalars["String"]>>>;
  id: Scalars["ID"];
  image_url?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
  property_id: Scalars["String"];
};

export enum TreatmentCategory {
  Massage = "MASSAGE",
  Other = "OTHER",
}

export enum Types {
  Alcohol = "ALCOHOL",
  Breakfast = "BREAKFAST",
  Desserts = "DESSERTS",
  Drinks = "DRINKS",
  HotDrinks = "HOT_DRINKS",
  Mains = "MAINS",
  Salads = "SALADS",
  Soups = "SOUPS",
  Starters = "STARTERS",
}

export type User = {
  __typename?: "User";
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["ID"];
  is_authorized?: Maybe<Scalars["Boolean"]>;
  is_disabled?: Maybe<Scalars["Boolean"]>;
  last_name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  profile_url?: Maybe<Scalars["String"]>;
  property_id: Scalars["String"];
  reports_to?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  shift_end?: Maybe<Scalars["String"]>;
  shift_start?: Maybe<Scalars["String"]>;
  working_days?: Maybe<Array<Maybe<Days>>>;
};

export type UserInput = {
  email: Scalars["String"];
  first_name: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  is_authorized?: InputMaybe<Scalars["Boolean"]>;
  is_disabled?: InputMaybe<Scalars["Boolean"]>;
  last_name: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  property_id: Scalars["String"];
  role?: InputMaybe<Scalars["String"]>;
  shift_end?: InputMaybe<Scalars["String"]>;
  shift_start?: InputMaybe<Scalars["String"]>;
  working_days?: InputMaybe<Array<InputMaybe<Days>>>;
};

export type DineInListQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type DineInListQuery = {
  __typename?: "Query";
  getEdibles?: Array<{
    __typename?: "DineInItem";
    id: string;
    property_id: string;
    name: string;
    type: string;
    img: string;
    desc: string;
    price: number;
    isVegan?: Diet_Type | null;
    availabletime?: string | null;
    extraOptions?: Array<{
      __typename?: "ExtraOptions";
      name: string;
      price?: number | null;
    } | null> | null;
    selections?: Array<{
      __typename?: "Selection";
      id: string;
      limit?: number | null;
      name: string;
      selection_items: Array<{
        __typename?: "SelectionItem";
        id: string;
        name: string;
        price?: number | null;
      } | null>;
    } | null> | null;
  } | null> | null;
};

export type GetPropertyOrdersQueryVariables = Exact<{
  orderStatus: Status;
  propertyID: Scalars["String"];
}>;

export type GetPropertyOrdersQuery = {
  __typename?: "Query";
  getOrdersByStatus?: Array<{
    __typename?: "Order";
    guest_id: number;
    guest_name: string;
    id: string;
    order_id: string;
    property_id: string;
    room_id: number;
    status?: Status | null;
    total: number;
    created_at: string;
    updated_at?: string | null;
    comment?: string | null;
    cart_items?: Array<{
      __typename?: "Cart";
      dineInItem_id: string;
      dineInItem_name: string;
      dineInItem_price: number;
      quantity: number;
      extras?: Array<{
        __typename?: "ExtraOptions";
        name: string;
        price?: number | null;
      } | null> | null;
      selections?: Array<{
        __typename?: "Selection";
        id: string;
        limit?: number | null;
        name: string;
        selection_items: Array<{
          __typename?: "SelectionItem";
          name: string;
          id: string;
          price?: number | null;
        } | null>;
      } | null> | null;
    }> | null;
  } | null> | null;
};

export type ChangeOrderStatusMutationVariables = Exact<{
  id: Scalars["String"];
  orderStatus: Status;
  propertyID: Scalars["String"];
}>;

export type ChangeOrderStatusMutation = {
  __typename?: "Mutation";
  changeOrderStatus?: string | null;
};

export type ModifyOrderMutationVariables = Exact<{
  orderInput: OrderInput;
}>;

export type ModifyOrderMutation = {
  __typename?: "Mutation";
  upsertOrder?: {
    __typename?: "OrderResponse";
    code: string;
    message?: string | null;
    order?: {
      __typename?: "Order";
      property_id: string;
      id: string;
      room_id: number;
      status?: Status | null;
      total: number;
      guest_id: number;
      guest_name: string;
      created_at: string;
      order_id: string;
      comment?: string | null;
      cart_items?: Array<{
        __typename?: "Cart";
        dineInItem_id: string;
        quantity: number;
        dineInItem_name: string;
        dineInItem_price: number;
        extras?: Array<{
          __typename?: "ExtraOptions";
          name: string;
          price?: number | null;
        } | null> | null;
        selections?: Array<{
          __typename?: "Selection";
          id: string;
          limit?: number | null;
          name: string;
          selection_items: Array<{
            __typename?: "SelectionItem";
            name: string;
            id: string;
            price?: number | null;
          } | null>;
        } | null> | null;
      }> | null;
    } | null;
  } | null;
};

export type CancelOrderMutationVariables = Exact<{
  id: Scalars["ID"];
  propertyID: Scalars["String"];
}>;

export type CancelOrderMutation = {
  __typename?: "Mutation";
  cancelOrder?: string | null;
};

export type GetPropertyDetailsQueryVariables = Exact<{
  propertyID: Scalars["String"];
}>;

export type GetPropertyDetailsQuery = {
  __typename?: "Query";
  getProperty?: { __typename?: "Property"; currency?: string | null } | null;
};

export const DineInListDocument = gql`
  query DineInList($propertyID: String!) {
    getEdibles(propertyID: $propertyID) {
      id
      property_id
      name
      type
      img
      desc
      price
      isVegan
      availabletime
      extraOptions {
        name
        price
      }
      selections {
        id
        limit
        name
        selection_items {
          id
          name
          price
        }
      }
    }
  }
`;

/**
 * __useDineInListQuery__
 *
 * To run a query within a React component, call `useDineInListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDineInListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDineInListQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useDineInListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DineInListQuery,
    DineInListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DineInListQuery, DineInListQueryVariables>(
    DineInListDocument,
    options
  );
}
export function useDineInListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DineInListQuery,
    DineInListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DineInListQuery,
    DineInListQueryVariables
  >(DineInListDocument, options);
}
export type DineInListQueryHookResult = ReturnType<typeof useDineInListQuery>;
export type DineInListLazyQueryHookResult = ReturnType<
  typeof useDineInListLazyQuery
>;
export type DineInListQueryResult = ApolloReactCommon.QueryResult<
  DineInListQuery,
  DineInListQueryVariables
>;
export const GetPropertyOrdersDocument = gql`
  query getPropertyOrders($orderStatus: Status!, $propertyID: String!) {
    getOrdersByStatus(orderStatus: $orderStatus, propertyID: $propertyID) {
      guest_id
      cart_items {
        dineInItem_id
        dineInItem_name
        dineInItem_price
        extras {
          name
          price
        }
        quantity
        selections {
          id
          limit
          name
          selection_items {
            name
            id
            price
          }
        }
      }
      guest_name
      id
      order_id
      property_id
      room_id
      status
      total
      created_at
      updated_at
      comment
    }
  }
`;

/**
 * __useGetPropertyOrdersQuery__
 *
 * To run a query within a React component, call `useGetPropertyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyOrdersQuery({
 *   variables: {
 *      orderStatus: // value for 'orderStatus'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPropertyOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPropertyOrdersQuery,
    GetPropertyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPropertyOrdersQuery,
    GetPropertyOrdersQueryVariables
  >(GetPropertyOrdersDocument, options);
}
export function useGetPropertyOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPropertyOrdersQuery,
    GetPropertyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPropertyOrdersQuery,
    GetPropertyOrdersQueryVariables
  >(GetPropertyOrdersDocument, options);
}
export type GetPropertyOrdersQueryHookResult = ReturnType<
  typeof useGetPropertyOrdersQuery
>;
export type GetPropertyOrdersLazyQueryHookResult = ReturnType<
  typeof useGetPropertyOrdersLazyQuery
>;
export type GetPropertyOrdersQueryResult = ApolloReactCommon.QueryResult<
  GetPropertyOrdersQuery,
  GetPropertyOrdersQueryVariables
>;
export const ChangeOrderStatusDocument = gql`
  mutation changeOrderStatus(
    $id: String!
    $orderStatus: Status!
    $propertyID: String!
  ) {
    changeOrderStatus(
      id: $id
      orderStatus: $orderStatus
      propertyID: $propertyID
    )
  }
`;
export type ChangeOrderStatusMutationFn = ApolloReactCommon.MutationFunction<
  ChangeOrderStatusMutation,
  ChangeOrderStatusMutationVariables
>;

/**
 * __useChangeOrderStatusMutation__
 *
 * To run a mutation, you first call `useChangeOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOrderStatusMutation, { data, loading, error }] = useChangeOrderStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orderStatus: // value for 'orderStatus'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useChangeOrderStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeOrderStatusMutation,
    ChangeOrderStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChangeOrderStatusMutation,
    ChangeOrderStatusMutationVariables
  >(ChangeOrderStatusDocument, options);
}
export type ChangeOrderStatusMutationHookResult = ReturnType<
  typeof useChangeOrderStatusMutation
>;
export type ChangeOrderStatusMutationResult =
  ApolloReactCommon.MutationResult<ChangeOrderStatusMutation>;
export type ChangeOrderStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ChangeOrderStatusMutation,
    ChangeOrderStatusMutationVariables
  >;
export const ModifyOrderDocument = gql`
  mutation modifyOrder($orderInput: OrderInput!) {
    upsertOrder(orderInput: $orderInput) {
      code
      message
      order {
        cart_items {
          dineInItem_id
          extras {
            name
            price
          }
          quantity
          dineInItem_name
          dineInItem_price
          selections {
            id
            limit
            name
            selection_items {
              name
              id
              price
            }
          }
        }
        property_id
        id
        room_id
        status
        total
        guest_id
        guest_name
        created_at
        order_id
        comment
      }
    }
  }
`;
export type ModifyOrderMutationFn = ApolloReactCommon.MutationFunction<
  ModifyOrderMutation,
  ModifyOrderMutationVariables
>;

/**
 * __useModifyOrderMutation__
 *
 * To run a mutation, you first call `useModifyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyOrderMutation, { data, loading, error }] = useModifyOrderMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *   },
 * });
 */
export function useModifyOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ModifyOrderMutation,
    ModifyOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ModifyOrderMutation,
    ModifyOrderMutationVariables
  >(ModifyOrderDocument, options);
}
export type ModifyOrderMutationHookResult = ReturnType<
  typeof useModifyOrderMutation
>;
export type ModifyOrderMutationResult =
  ApolloReactCommon.MutationResult<ModifyOrderMutation>;
export type ModifyOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ModifyOrderMutation,
  ModifyOrderMutationVariables
>;
export const CancelOrderDocument = gql`
  mutation cancelOrder($id: ID!, $propertyID: String!) {
    cancelOrder(id: $id, propertyID: $propertyID)
  }
`;
export type CancelOrderMutationFn = ApolloReactCommon.MutationFunction<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >(CancelOrderDocument, options);
}
export type CancelOrderMutationHookResult = ReturnType<
  typeof useCancelOrderMutation
>;
export type CancelOrderMutationResult =
  ApolloReactCommon.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;
export const GetPropertyDetailsDocument = gql`
  query getPropertyDetails($propertyID: String!) {
    getProperty(propertyID: $propertyID) {
      currency
    }
  }
`;

/**
 * __useGetPropertyDetailsQuery__
 *
 * To run a query within a React component, call `useGetPropertyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyDetailsQuery({
 *   variables: {
 *      propertyID: // value for 'propertyID'
 *   },
 * });
 */
export function useGetPropertyDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >(GetPropertyDetailsDocument, options);
}
export function useGetPropertyDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPropertyDetailsQuery,
    GetPropertyDetailsQueryVariables
  >(GetPropertyDetailsDocument, options);
}
export type GetPropertyDetailsQueryHookResult = ReturnType<
  typeof useGetPropertyDetailsQuery
>;
export type GetPropertyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetPropertyDetailsLazyQuery
>;
export type GetPropertyDetailsQueryResult = ApolloReactCommon.QueryResult<
  GetPropertyDetailsQuery,
  GetPropertyDetailsQueryVariables
>;
