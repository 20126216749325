import { Amplify } from 'aws-amplify';

const initAWSAmplify = () => {
  const {
    REACT_APP_REGION,
    REACT_APP_COGNITO_POOL_ID,
    REACT_APP_COGNITO_WEB_POOL_ID,
    REACT_APP_URL,
  } = process.env;
  Amplify.configure({
    Auth: {
      region: REACT_APP_REGION,
      userPoolId: REACT_APP_COGNITO_POOL_ID,
      userPoolWebClientId: REACT_APP_COGNITO_WEB_POOL_ID,
      oauth: {
        redirectSignIn: REACT_APP_URL,
        redirectSignOut: `${REACT_APP_URL}/login`,
        scope: ['email', 'openid', 'aws.cognito.signin.user.domain'],
        responseType: 'code',
      },
    },
  });
};

export default initAWSAmplify;
