import AppSyncConfig from "./aws-exports";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Operation,
} from "@apollo/client";
import { onError } from "@apollo/link-error";
import * as Sentry from "@sentry/react";
import { setContext } from "@apollo/client/link/context";


const isPrivateRequest = (op: Operation) =>
  op.getContext().clientName !== "PUBLIC";

const authContext = setContext(async () => {
  return {
    headers: {
      "X-Api-Key": process.env.REACT_APP_API_KEY,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      Sentry.captureMessage(
        `[GraphQL error]: Message: ${message},
         Location: ${locations}, Path: ${path}`
      )
    );
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authContext,
    ApolloLink.split(  
      (op) => isPrivateRequest(op),
      new HttpLink({
        uri: `${AppSyncConfig.aws_appsync_graphqlEndpoint}`,
      })
    ),
  ]),
  cache: new InMemoryCache(),
});

export default client;
