import { Auth, Cache } from 'aws-amplify';
import meQuery from '../utils/meQuery';
import client from './apolloClient';

export const isExpired = (jwtToken: any) => {
  if (!jwtToken) {
    return true;
  }
  const ts = new Date().getTime();
  const delta = 10 * 60 * 1000; // 10 minutes
  return jwtToken.exp * 1000 <= ts + delta;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const getToken = (): string | null => Cache.getItem('session');

export const deleteToken = () => {
  Cache.removeItem('session');
  Cache.clear();
};

export const getJWTToken = async () => {
  let session = Cache.getItem('session');
  if (session) {
    const token = session.idToken.jwtToken;
    const jwtToken = parseJwt(token);
    if (isExpired(jwtToken)) {
      await Auth.currentUserPoolUser();
      session = await Auth.currentSession();
      Cache.setItem('session', session);
    }
    return session.idToken.jwtToken;
  }
  session = await Auth.currentSession();
  Cache.setItem('session', session);
  return session.idToken.jwtToken;
};

export const refreshAuthentication = async () => {
  try {
    const token = await getJWTToken();
    if (isExpired(token)) {
      Cache.removeItem('session');
      Cache.removeItem('user');
      return;
    }
    const { data } = await client.query({
        query: meQuery,
        variables: {
            domain: process.env.REACT_APP_PROPERTYID,
          },
        fetchPolicy: 'network-only',

      });
      const currentUser = data?.getPropertyByDomain
    Cache.setItem('user', currentUser);
  } catch (error) {
    Cache.removeItem('user');
  }
};