import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import * as React from "react";
import { ExtraOptions, Order } from "../../generated/graphql";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import RamenDiningIcon from "@mui/icons-material/RamenDining";

interface props {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  currency: string;
  order:Order|null
}

const OrderDetailDrawer = ({
  open,
  handleClose,
  handleOpen,
  currency,
  order
}: props) => {
  return (
    <>
      <SwipeableDrawer
        open={open}
        anchor="right"
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <Box>

            <Card sx={{ margin: 1, width: "300px" }}>
              <CardHeader
                style={{ backgroundColor: "#21BFAE" }}
                title={
                  <Stack direction="row" alignItems="center" gap={1} mb={1}>
                    <Typography align="right" color="#fff">
                      Room {order?.room_id}
                    </Typography>
                  </Stack>
                }
                subheader={
                  <Stack direction="row" justifyContent="space-between">
                    <Typography align="left" color="#fff">
                      OrderID: {order?.order_id}
                    </Typography>
                    <Typography align="right" color="#fff">
                      {order?.guest_name}
                    </Typography>
                  </Stack>
                }
              ></CardHeader>
              <CardContent>
                <Divider />
                <Box mt={1} mb={2}>
                  {order?.cart_items?.map((cart: any) => {
                    return (
                      <>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={1}
                          mb={1}
                        >
                          <RamenDiningIcon />
                          <Typography style={{ fontWeight: 600 }}>
                            {" "}
                            Menu Items
                          </Typography>
                        </Stack>
                        <Stack
                          key={cart.dineInItem_id}
                          direction="row"
                          justifyContent="space-between"
                          spacing={0.5}
                        >
                          <Stack direction="row" spacing={2}>
                            <Typography variant="body2" color="black">
                              {cart.quantity}X
                            </Typography>
                            <Typography variant="body2" fontSize="15px">
                              {cart.dineInItem_name}
                            </Typography>
                          </Stack>
                          <Typography variant="body2" fontSize="15px">
                            {(cart.dineInItem_price * cart.quantity).toFixed(2)}
                          </Typography>
                        </Stack>

                        {cart?.extras?.map((extra: ExtraOptions) => {
                          return (
                            <Stack
                              key={cart.dineInItem_id}
                              direction="row"
                              justifyContent="start"
                              marginLeft="35px"
                            >
                              <Typography variant="body2" fontSize="12px">
                                {extra.name}
                              </Typography>
                            </Stack>
                          );
                        })}

                        {cart?.selections && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            mb={1}
                            mt={1}
                          >
                            <LibraryAddCheckIcon />
                            <Typography style={{ fontWeight: 600 }}>
                              Selections
                            </Typography>
                          </Stack>
                        )}

                        {cart?.selections?.map((select: any) => {
                          return (
                            <>
                              <Typography variant="body2" fontSize="15px">
                                {select.name}
                              </Typography>
                              {select?.selection_items.map((items: any) => {
                                return (
                                  <Stack
                                    key={cart.dineInItem_id}
                                    direction="row"
                                    justifyContent="space-between"
                                    spacing={0.5}
                                  >
                                    <Stack direction="row" spacing={2}>
                                      <Typography
                                        variant="body2"
                                        fontSize="12px"
                                        fontWeight="200px"
                                      >
                                        {items.name}
                                      </Typography>
                                    </Stack>
                                    <Typography variant="body2" fontSize="12px">
                                      {items.price}
                                    </Typography>
                                  </Stack>
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </Box>
                <Divider />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  gap={1}
                  mb={1}
                >
                  <Typography style={{ fontWeight: 600 }}>
                    Total Amount
                  </Typography>
                  <Typography
                    variant="body2"
                    align="right"
                    color="black"
                    style={{ fontWeight: 600 }}
                  >
                    {currency} {order?.total.toFixed(2)}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default OrderDetailDrawer;
