import { useEffect, useState } from "react";
import { Status, useGetPropertyDetailsQuery, useGetPropertyOrdersQuery } from "../../generated/graphql";
import { useAuthProvider } from "../../core/authContext";
const useOrderHistory = () => {
  const { getUser } = useAuthProvider();
  const user = getUser();
  const [orderHistory, setOrderHistory] = useState<any>([]);
  const { data: cancelOrder, loading: loadingCancelOrder } =
    useGetPropertyOrdersQuery({
      fetchPolicy: "no-cache",
      variables: {
        orderStatus: Status.Cancelled,
        propertyID: user?.id,
      },
    });

  const { data: completedOrder, loading: loadingCompletedOrder } =
    useGetPropertyOrdersQuery({
      fetchPolicy: "no-cache",
      variables: {
        orderStatus: Status.Delivered,
        propertyID: user?.id,
      },
    });
    const { data: propertyDetails } = useGetPropertyDetailsQuery({
      fetchPolicy: "network-only",
      variables: {
        propertyID: user?.id,
      },
    });
  useEffect(() => {
    const cancel: any = cancelOrder?.getOrdersByStatus;

    if (cancel) {
      setOrderHistory(completedOrder?.getOrdersByStatus?.concat(cancel));
    } else {
      setOrderHistory(completedOrder?.getOrdersByStatus);
    }
  }, [completedOrder, cancelOrder]);

  const sortData = orderHistory?.sort((a: any, b: any) => {
    return new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf();
  });

  return {
    details: propertyDetails?.getProperty,
    orderHistory: sortData,
    loadingCancelOrder,
    loadingCompletedOrder,
  };
};

export default useOrderHistory;
